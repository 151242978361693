<mat-toolbar>
  <mat-toolbar-row>
    <mat-nav-list>
      <a
        #rla1="routerLinkActive"
        mat-list-item
        routerLink="/home"
        routerLinkActive
        [activated]="rla1.isActive"
      >
        <mat-icon
          matListItemIcon
          matRipple
          >home</mat-icon
        >Home
      </a>
      @if (team$ | async; as team) {
        <a
          #rla2="routerLinkActive"
          mat-list-item
          routerLinkActive
          [activated]="rla2.isActive"
          [routerLink]="['teams', team.id]"
        >
          <mat-icon
            matListItemIcon
            matRipple
            >groups_3</mat-icon
          >Squadra
        </a>
      }
      @if (championship$ | async; as championship) {
        <a
          #rla3="routerLinkActive"
          mat-list-item
          routerLinkActive
          [activated]="rla3.isActive"
          [routerLink]="['championships', championship.id]"
        >
          <mat-icon
            matListItemIcon
            matRipple
            >emoji_events</mat-icon
          >Lega
        </a>
      }
      @if (loggedIn$ | async) {
        <a
          #rla5="routerLinkActive"
          mat-list-item
          routerLink="/user"
          routerLinkActive
          [activated]="rla5.isActive"
        >
          <mat-icon
            matListItemIcon
            matRipple
            >account_circle</mat-icon
          >Io
        </a>
      } @else {
        <a
          #rla7="routerLinkActive"
          mat-list-item
          routerLink="/clubs"
          routerLinkActive
          [activated]="rla7.isActive"
        >
          <mat-icon
            matListItemIcon
            matRipple
            >sports_soccer</mat-icon
          >Clubs
        </a>
        <a
          #rla6="routerLinkActive"
          mat-list-item
          routerLink="/auth/login"
          routerLinkActive
          [activated]="rla6.isActive"
        >
          <mat-icon
            matListItemIcon
            matRipple
            >input</mat-icon
          >Login
        </a>
      }
    </mat-nav-list>
  </mat-toolbar-row>
</mat-toolbar>
