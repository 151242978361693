<eco-fab-speed-dial
  animationMode="scale"
  class="tertiary-fab"
  [direction]="direction()"
  [fixed]="false"
  [(open)]="openSpeeddial"
>
  <eco-fab-speed-dial-trigger [spin]="true">
    <button
      aria-label="Aggiungi"
      mat-fab
      type="button"
      [extended]="extended()"
    >
      <mat-icon class="spin360">add</mat-icon>
      @if (extended()) {
        Aggiungi
      }
    </button>
  </eco-fab-speed-dial-trigger>

  <eco-fab-speed-dial-actions>
    <button
      *appSeasonActive
      class="mat-accent"
      mat-mini-fab
      matTooltip="Inserisci formazione"
      matTooltipPosition="left"
      title="Inserisci formazione"
      type="button"
      (click)="navigate('/lineups/new')"
    >
      <mat-icon svgIcon="soccer_field" />
    </button>
    <button
      class="mat-accent"
      mat-mini-fab
      matTooltip="Nuovo articolo"
      matTooltipPosition="left"
      type="button"
      (click)="navigate('/articles/new')"
    >
      <mat-icon>message</mat-icon>
    </button>
    <button
      *appSeasonActive
      class="mat-accent"
      mat-mini-fab
      matTooltip="Nuovo trasferimento"
      matTooltipPosition="left"
      type="button"
      (click)="navigate('/transferts/new')"
    >
      <mat-icon>swap_vert</mat-icon>
    </button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
