@let isTablet = isTablet$ | async;
@let isHandset = isHandset$ | async;
@let isOpen = isOpen$ | async;
@let isScrolled = isScrolled$ | async;
@let showedToolbar = showedToolbar$ | async;
<mat-sidenav-container>
  <mat-sidenav
    #drawer
    fixedInViewport="true"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [autoFocus]="false"
    [mode]="isHandset ? 'over' : isTablet && isOpen ? 'over' : 'side'"
    [ngClass]="{
      rail: isTablet,
      'rail-closed': isTablet && !drawer.opened,
      'rail-opened': isTablet && drawer.opened,
    }"
    [opened]="isOpen"
    (openedChange)="open($event)"
  >
    <app-navbar [sidenav]="drawer" />
  </mat-sidenav>
  <mat-sidenav-content>
    <app-toolbar
      [@scrollUpAnimation]="isScrolled === false || showedToolbar"
      [ngClass]="{ 'is-scrolled': isScrolled }"
    />
    <main
      [@contextChange]="o | state"
      [style.viewTransitionName]="viewTransitionName()"
    >
      <router-outlet #o="outlet" />
    </main>

    <div
      class="bottom"
      [@scrollDownAnimation]="showedToolbar"
    >
      <app-bottom [visibility]="(showedSpeedDial$ | async) ?? hidden" />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
